import React, { useEffect } from 'react';
// import Layout from '../../components/layout/LayoutTracking';
import { navigate } from 'gatsby';

const ContractAnalysis = () => {
	useEffect(() => { navigate('/404') }, [])
	return null
	// return <Layout>&nbsp;</Layout>;
};	

export default ContractAnalysis;
